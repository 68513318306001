import React from "react"
import Link from "gatsby-link"
import Layout from "../layouts/index"

export default () => (
  <Layout>
    <div className="hero">
      <div className="content-wrap">
        <h1>
          基于<span>人工智能</span>技术的
          <br />
          企业自动化平台
        </h1>
        <p>
          端到端业务管理，以低成本获得高附加值的管理服务
          <br />
          2019年度中国AI商业应用领域<span>最具影响力创新企业</span>
        </p>
        <div className="awards-group">
          <div className="award-info">
            <div className="award-pic"></div>
            <p>
              Forrester
              <br />
              2019
              <br />
              行业领袖
            </p>
          </div>
          <div className="award-info">
            <div className="award-pic"></div>
            <p>
              深圳市智慧杆
              <br />
              产业促进会
              <br />
              理事单位
            </p>
          </div>
        </div>
        <h4>4M 终端用户 20K 技术人员 10M 工单</h4>
        <h4>全球 7 个 SaaS 中心 11 种语言</h4>
        <h4>面向特定客户需求的公有云和混合云</h4>
      </div>
    </div>
    <section>
      <div className="content-wrap">
        <div className="product-intro luma">
          <div className="title">
            <h2>
              Luma
              <br />
              虚拟助理
            </h2>
            <h3>一个崭新的划时代智能对话平台</h3>
          </div>
          <p>
            40% 的企业客服响应时间长达 1-4 个小时，而 Luma
            可以做到即刻响应，客服整体流转效率提升 30%
          </p>
          <p>
            Luma
            虚拟服务助理通过自然语言处理（NLP）技术，进行高效的工单分派、结合智能问答等功能提升企业服务管理效率，通过自然语言对话的沟通方式，企业可以方便的引导最终用户完成自助服务，节约大量的时间和金钱
          </p>
          <h4>减少 88% 邮件、电话和沟通的次数，提高 71% 客户满意度</h4>
          <h4>可定制的 Web组件，可灵活嵌入Sharepoint、内网、外网站点</h4>
          <h4>适配多种企业 IM 环境，包括微信、钉钉、Microsoft Teams等</h4>
          <div className="btn-group">
            <Link
              to="/products/product-luma"
              className="btn btn-solid btn-blue"
            >
              了解更多
            </Link>
            <Link to="/" className="btn btn-outline btn-blue">
              申请试用
            </Link>
          </div>
        </div>
        <div className="product-intro auto-eng">
          <div className="product-info">
            <div className="title">
              <h2>Luma自动化</h2>
              <h3>图形化的工作流设计工具</h3>
            </div>
            <p>
              在 Luma 的帮助下，自动化更容易了。Luma
              自动化引擎包含图形化的工作流设计工具。您可以只写少量代码甚至无需编写代码来创建您的自动化服务，从而解放IT部门和分析师，使他们可以专注在高价值的业务上。
            </p>
            <p>
              Luma
              包含大量开箱即用的任务模块，涵盖员工入职、密码重置以及基础设施的监控修复等，您可以通过简单的配置部署适合行业的个性化方案。
            </p>
            <div className="btn-group">
              <Link
                to="/products/product-auto"
                className="btn btn-solid btn-blue"
              >
                了解更多
              </Link>
            </div>
          </div>
        </div>
        <div className="product-intro ism">
          <div className="title">
            <h2>Luma服务管理</h2>
            <h3>卓越的下一代智能服务管理系统</h3>
          </div>
          <p>
            您的服务和IT系统好坏是通过客户的满意度来评价的，因此您必须响应并准确地解决现有客服水准中遭遇的挑战。
            <br />
            为了实现这些目标，您需要自动化来推进方案效果，您需要我们的智能服务管理解决方案，它涵盖全面的客服管理，包括IT服务管理(ITSM)。
          </p>
          <div className="product-icons">
            <div className="p-icon p1">9 大 ITIL认证流程</div>
            <div className="p-icon p2">自动化工作流程</div>
            <div className="p-icon p3">IT 资产管理系统</div>
            <div className="p-icon p4">直观配置轻松上手</div>
            <div className="p-icon p5">打通 CRM系统</div>
          </div>
          <div className="btn-group">
            <Link to="/products/product-ism" className="btn btn-solid btn-blue">
              了解更多
            </Link>
            <Link to="/" className="btn btn-outline btn-blue">
              申请试用
            </Link>
          </div>
        </div>
        <div className="product-intro asp">
          <div className="product-info">
            <div className="title">
              <h2>Luma智能运维</h2>
              <h3>利用机器学习改善自动化运维</h3>
            </div>
            <p>
              AIOps (Artificial Intelligence for IT Operations)
              ，智能化运维，将人工智能应用于运维领域，基于已有的运维大数据（日志、指标、监控信息、应用信息等），通过机器学习的方式来进一步解决自动化运维没办法解决的问题。AIOps
              在自动化运维的基础上，增加了一个基于机器学习的大脑，指挥监测系统采集大脑决策所需的数据，做出分析、决策，并指挥自动化脚本去执行大脑的决策，从而达到运维系统的整体目标（噪声消除、根源分智能预警等）。
            </p>
            <div className="btn-group">
              <Link
                to="/products/product-AIOps"
                className="btn btn-solid btn-blue"
              >
                了解更多
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="home-solution">
        <div className="home-solution-bg-top">
          <div className="home-solution-bg"></div>
          <div className="home-solution-bg"></div>
          <div className="home-solution-bg"></div>
          <div className="home-solution-bg"></div>
        </div>
        <div className="home-solution-content">
          <div className="content-wrap">
            <h2>解决方案与行业案例</h2>
            <h3>通过 AI 技术为以下行业赋能，提供精准高效的服务</h3>
            <div className="solution-types">
              <div className="solution-type solution-type1">零售</div>
              <div className="solution-type solution-type2">智慧城市</div>
              <div className="solution-type solution-type3">出行</div>
              <div className="solution-type solution-type4">旅游</div>
              <div className="solution-type solution-type5">物联网</div>
              <div className="solution-type solution-type6">银行</div>
            </div>
            <div className="solution-cards">
              <div className="solution-card">
                <div className="thumb"></div>
                <h2>深圳智慧城市 IOT</h2>
                <p>
                  提供符合数字化管理的物联网管理方案，提高城市效能
                  <br />
                  通过 Luma 自动识别市民可能提出的问题
                  <br />
                  实现 Zero Touch 自动化
                  <br />
                  带来的效益：
                  <br />· 深圳智慧杆项目
                  <br />· 预测性的和规范的
                  <br />· 经济的数据驱动
                </p>
              </div>
              <div className="solution-card">
                <div className="thumb"></div>
                <h2>Panasonic</h2>
                <p>
                  Zero Touch Automation - 无需人工干预的自动化调度
                  <br />
                  引擎
                  <br />
                  提供数字化流程、为员工创造出色的体验并释放生产力
                  <br />· 松下提供 IT 数字化流程、为东盟 10 个国家提供支持
                  <br />· 实施仅需几周时间
                  <br />· 优化 50% 工作流程
                </p>
              </div>
              <div className="solution-card">
                <div className="thumb"></div>
                <h2>富士通</h2>
                <p>
                  富士通通过部署虚拟助手
                  Luma，大大提高了客户满意度和服务质量。灵活有效地满足市场变化，资源调度和客户的多样需求
                  <br />
                  带来的效益：
                  <br />· 降低后台人力成本 50%
                  <br />· 缩短 IOT 传感器工作延时
                  <br />· 部署系统时间仅需 2 个月，而其他供应商需要半年
                </p>
              </div>
              <div className="solution-card">
                <div className="thumb"></div>
                <h2>Onstar 中国数字变革</h2>
                <p>
                  安吉星的IT、Care、TS、QD、Finance、Legal、QA部门
                  <br />
                  每天响应1500+车主服务，提高20%的工作效率
                  <br />· 提高生产力并连通信息孤岛
                  <br />· 基于大数据分析的商业决策
                  <br />· 基于 ITIL 和自动化的过程标准化
                  <br />· 尽可能减少纸质办公
                </p>
              </div>
            </div>
            <div className="btn-group">
              <Link to="/solutions" className="btn-l btn-solid btn-deepblue">
                查看更多案例
              </Link>
              <Link to="/" className="btn-l btn-outline btn-deepblue">
                申请试用体验
              </Link>
            </div>
            <div className="home-clients">
              <h2>他们已开始使用 ServiceAI</h2>
              <div className="clients-group">
                <div className="client-logo"></div>
                <div className="client-logo"></div>
                <div className="client-logo"></div>
                <div className="client-logo"></div>
                <div className="client-logo"></div>
                <div className="client-logo"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
